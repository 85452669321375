@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alata&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.section-center {
  @apply my-16 xl:my-40 max-w-xs sm:max-w-md mx-auto font-sans md:max-w-4xl lg:max-w-5xl xl:max-w-7xl  px-4 md:px-6  lg:px-8;
}
.back-image {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('./assets/salvation.png');

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 80vh; */

  overflow: hidden;
}
.backd-image {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('./assets/aboutH.jpeg');

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 80vh; */

  overflow: hidden;
}
.backdd-image {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('./assets/abt.png');

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 80vh; */

  overflow: hidden;
}
.backdd-imagee {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url('./assets/dd.png');

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 80vh; */

  overflow: hidden;
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  content: '';
  position: absolute;
  width: 24px;
  height: 3px;
  top: 0;
  left: 0;
  background: #222e50;
  transform: rotate(0);
  transition: all 0.5s;
}

.phamburger-top,
.phamburger-middle,
.phamburger-bottom {
  content: '';
  position: absolute;
  width: 24px;
  height: 3px;
  top: 0;
  left: 0;
  background: #fff;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.phamburger-middle {
  transform: translateY(7px);
}
.hamburger-bottom {
  transform: translateY(14px);
  background: #222e50;
}
.phamburger-bottom {
  transform: translateY(14px);
  background: #fff;
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
  position: fixed;
  top: 37px;
  right: 25px;
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
  background-color: #fff;
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
  background-color: #fff;
}

@import '~antd/dist/antd.css';

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: 5em 0 8em;
  margin: 2em auto;
}
.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery3 {
  grid-column: 1 /-1;
}

.about_content {
  display: flex;
  margin: 5em 0;
  padding: 0 2rem;
  justify-content: space-around;
  align-items: center;
}
.buttons,
.about_button {
  background-color: #a303a0;
  color: white;
  font-weight: 700;
  border-radius: 8px;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_button {
  width: 30%;
}
.btns {
  background-color: transparent;
  color: #a303a0;
  border-color: #a303a0;
}
.about_content:first-of-type {
  margin-top: 8em;
}
.about_content > div {
  width: 40%;
}
.about_content > img {
  height: 350px;
  width: 35%;
  object-fit: cover;
}

.about_content p {
  margin-top: 1.5rem;
}

.video__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.hero_bg {
  background-position: 'center';
}
.video-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(1.2px);
  filter: brightness(0.5);
  border: none;
  outline: none;
}
.iframe-bg {
  background: black;
  margin: 120px 0 0;
  /* width: 100vw; */
}

.inputs,
select {
  height: 2.5rem;
  border: 2px solid #a303a0;
  border-radius: 6px;
}
.select > div {
  height: 100% !important;
  border: none !important;
}
textarea {
  border: 2px solid #a303a0;
}
.iframe-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.iframe {
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
}
.salvation {
  background-image: url('./assets/salvation.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 95%;
  margin: 0 auto;
}
.chatBtn {
  position: fixed;
  background: transparent;
  right: 5%;
  bottom: 5%;
  z-index: 9999999;
  width: 150px;
}
.main {
  width: 70px;
  background-color: white;
  height: 70px;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
}
@media (max-width: 768px) {
  .about_content {
    padding: 0;
    margin: 3em 0 !important;
  }
  .about_button {
    width: 50%;
    margin: 1em auto;
  }
  .about_content {
    flex-direction: column;
  }
  .about_content.rev {
    flex-direction: column-reverse;
  }
  .about_content > div {
    width: 100%;
    margin-top: 2rem;
  }
  .about_content > img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

@media (max-width: 650px) {
  .gallery {
    grid-template-columns: 1fr;
  }
  .hero_bg {
    background-position: 'left';
  }
}
